import React, { useState, useEffect } from 'react';
import Form from '@nubank/nuds-web/components/Form/Form';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Button from '@nubank/nuds-web/components/Button/Button';
import Snackbar from '@nubank/nuds-web/components/Snackbar/Snackbar';
import styled from 'styled-components';
import Box from '@nubank/nuds-web/components/Box/Box';
import PropTypes from 'prop-types';

import useFigPiiExperiment from '@nubank/www-latam-commons/utils/figpii/useFigPiiExperiment';

import { FormStepContainer } from '../../styles/FormStepContainer';
import { useRegistrationFormContext } from '../RegistrationForm/RegistrationForm';
import { getAddressDataFromPostCode } from '../../../../domains/address/postcode';
import states from '../../../../utils/form/states';
import { addressStepRegisterEvent, unknownZipCodeRegisterEvent, displayedFunnelScreensEvent } from '../../tracking';
import { ERROR_SEVERITY, sentryException } from '../../../../utils/sentry';
import { useSiteContext } from '../../../../components/SiteContext/SiteContext';
import { createAuthorizationRequest } from '../../../../domains/prospect/bureauAuthorizationRequest';

import TextInput from './TextInput';
import CustomSelectField from './CustomSelectField';
import Tooltip from './Tooltip';
import Disclaimer from './Disclaimer';
import UserAddressSearchForm from './UserAddressSearchForm';

// OTP Experiment
const OTP_EXPERIMENT_ID = '343024';
const OTP_EXPERIMENT_VARIANT = '44286';

const StyledSection = styled(Box)`
  button {
    min-width: 64px;
    height: 64px;
    margin-left: 56px;
  }
`;

const LOCALITY_NOT_FOUND = 'LOCALITY_NOT_FOUND';

const NumberInputsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 12px;
`;

const isPostalCodeValid = value => /^\d+$/.test(value) && value.length === 5;
const isNotEmptyAndAtLeast3CharsSpecialChars = value => value.trim() !== '' && value.length >= 3 && !/[^((0-9)|(a-zA-Z)|(\u00C0-\u00FF)|.\-,|\s)]/.test(value);
const isNotEmptyAndSpecialChars = value => value.trim() !== '' && !/[^((0-9)|(a-zA-Z)|(\u00C0-\u00FF)|.\-,|\s)]/.test(value);

const stateAndCityAreEmpty = (state, city) => {
  if (!state || !city) {
    return true;
  }

  return state.trim() === '' || city.trim() === '';
};

const getStateValueByName = stateName => states.find(state => state.label === stateName)?.value || `State value not found for: ${stateName}`;

const ZipAddressStepWithSearch = ({ shouldTriggerOTP }) => {
  // OTP Experiment
  const activeOTPVariant = useFigPiiExperiment(OTP_EXPERIMENT_ID);

  const { setSelectedAddress } = useRegistrationFormContext();
  const [showManualInputs, setShowManualInputs] = useState(false);
  const [formData, setFormData] = useState({
    streetNumberExt: '',
    streetNumberInt: '',
    street: '',
    locality: '',
    localityManualInput: '', // for internal use only
    city: '',
    addressState: '',
    postCode: '',
    prospectUsedManualInputs: false, // for internal use only
  });
  const [validations, setValidations] = useState({
    postCode: false,
    locality: false,
    street: false,
    streetNumberExt: false,
    streetNumberInt: true,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [localityOptions, setLocalityOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [showManualButton, setShowManualButton] = useState(false);
  const {
    registrationFlowEvents, updateRegistrationFlowEvents, phone, setAuthorizationRequest,
    discoveryUrlsList,
  } = useSiteContext();

  let debounceTimer;

  // This one is used for TextInputs changes (street, streetNumberExt and streetNumberInt)
  const handleChange = e => {
    const { name, value } = e.target;

    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));

    let isValid = false;

    // Validate required fields, at least 3 chars and no special chars
    // ignore streetNumberInt and streetNumberExt because it has custom validations
    if (value.trim() !== '' && name !== 'streetNumberInt' && name !== 'streetNumberExt') {
      isValid = isNotEmptyAndAtLeast3CharsSpecialChars(value);
    }

    // validate streetNumberExt with no special chars and at least 1 number
    if (name === 'streetNumberExt' && value.trim() !== '') {
      isValid = isNotEmptyAndSpecialChars(value);
    }

    // validate streetNumberInt only if it has a value
    if (name === 'streetNumberInt') {
      isValid = value.trim().length > 0 ? isNotEmptyAndSpecialChars(value) : true;
    }

    if (showManualInputs && name === 'postCode') {
      isValid = isPostalCodeValid(value);
    }

    setValidations(prevValidations => ({
      ...prevValidations,
      [name]: isValid,
    }));
  };

  // This one is used for SelectInputs changes (state, city and locality)
  const handleSelectChange = (name, value) => {
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
      localityManualInput: value === LOCALITY_NOT_FOUND ? '' : prevData.localityManualInput, // reset manual input if locality is not found
    }));

    let isValid = false;

    // Validate required fields
    if (value.trim() !== '') {
      isValid = isNotEmptyAndSpecialChars(value);
    }

    setValidations(prevValidations => ({
      ...prevValidations,
      [name]: isValid,
    }));
  };

  // This one is used just for manual input locality
  const handleLocalityManualInput = async e => {
    const { value } = e.target;

    setFormData(prevData => ({
      ...prevData,
      localityManualInput: value,
    }));

    let isValid = false;

    // Validate manual input locality, at least 3 chars and no special chars
    if (value.trim() !== '') {
      isValid = isNotEmptyAndAtLeast3CharsSpecialChars(value);
    }

    setValidations(prevValidations => ({
      ...prevValidations,
      localityManualInput: isValid,
      locality: true,
    }));
  };

  const getPostalCodeData = async postCode => {
    try {
      const response = await getAddressDataFromPostCode(postCode, discoveryUrlsList);

      // set city options so label is displayed in input
      setCityOptions([{
        label: response.city,
        value: response.city,
      }]);

      // set localities options, with a default 'not found' so the user can select one.
      setLocalityOptions([
        ...response?.localities.map(name => ({
          label: name,
          value: name,
        })).sort((a, b) => a.label.localeCompare(b.label)),
        { label: 'Mi colonia no está aquí', value: LOCALITY_NOT_FOUND }] || []);

      // map the response and set the state and city with the response
      // if localities response is only one, set it as selected
      setFormData(prevData => ({
        ...prevData,
        addressState: getStateValueByName(response.state),
        city: response.city,
        locality: response.localities.length === 1 ? response.localities[0] : '',
        prospectUsedManualInputs: false,
      }));

      // set validations true to postcode, city and locality if only one locality is returned
      setValidations(prevValidations => ({
        ...prevValidations,
        postCode: true,
        addressState: true,
        city: true,
        locality: response.localities.length === 1,
      }));
    } catch (error) {
      setShowManualButton(true);

      setValidations(prevValidations => ({
        ...prevValidations,
        postCode: false,
      }));

      sentryException({
        error,
        flow: 'application_flow',
        checkpoint: 'Zip Address Step - getPostalCodeData',
        namespace: '<ZipAddressStep />',
        level: ERROR_SEVERITY.CRITICAL,
      });
    }
  };

  const handlePostCodeChange = async e => {
    const { value } = e.target;

    setShowManualButton(false);

    // Reset the form  state, locality and city if the user deletes the zip code
    setFormData(prevData => ({
      ...prevData,
      addressState: '',
      locality: '',
      localityManualInput: '',
      city: '',
      postCode: value,
    }));

    setValidations(prevValidations => ({
      ...prevValidations,
      postCode: isPostalCodeValid(value),
    }));

    // Clear the previous debounce timer
    clearTimeout(debounceTimer);

    // Set a new debounce timer
    debounceTimer = setTimeout(async () => {
      if (value.length === 5 && isPostalCodeValid(value)) {
        setIsLoading(true);

        await getPostalCodeData(value);

        setIsLoading(false);
      }
    }, 1000); // 1 second debounce delay
  };

  const handleFormStepSubmit = async ({
    nextStep,
    setSubmitting,
    setFormErrorMsg,
  }) => {
    setSubmitting(true);

    // Validate that user doesnt skip any field, even if they try to skip the disabled button
    if (!formData.addressState || !formData.city
          || !formData.locality || !formData.street
           || !formData.streetNumberExt) {
      setSubmitting(false);
      setFormErrorMsg('Por favor, completa todos los datos');
      return;
    }

    if (formData.locality === LOCALITY_NOT_FOUND) {
      formData.locality = formData.localityManualInput;
    }

    setSelectedAddress(formData);

    if (!registrationFlowEvents.address) {
      addressStepRegisterEvent();
      updateRegistrationFlowEvents({ ...registrationFlowEvents, address: true });
    }

    const channels = activeOTPVariant === OTP_EXPERIMENT_VARIANT ? ['sms', 'whatsapp'] : ['sms'];

    try {
      // This request triggers the sms for OTP validation
      if (shouldTriggerOTP) {
        const authorizationRequestValues = await createAuthorizationRequest({
          phone,
          urls: discoveryUrlsList,
          channels,
          includeOriginURL: false,
        });

        setAuthorizationRequest({
          id: authorizationRequestValues.id,
          flowId: authorizationRequestValues.flow_id,
        });
      }

      nextStep();
    } catch (error) {
      sentryException({
        error,
        flow: 'application_flow',
        checkpoint: 'address_step_submit',
        namespace: '<ZipAddressStep />',
        level: ERROR_SEVERITY.ERROR,
      });

      setSubmitting(false);
      setFormErrorMsg('Ocurrió un error, por favor intenta de nuevo, si el problema persiste, por favor contacta a soporte');
    }
  };

  const isFormValid = Object.values(validations).every(isValid => isValid);

  const getTooltipMessage = value => {
    if (formData.postCode.length === 5 && !validations.postCode) {
      return 'Continua en "Encontrar mi Código Postal"';
    }

    if (value === 0) {
      return 'Comienza llenando el código postal';
    }
    return 'Lo hemos llenado por ti';
  };

  const getpostcodeErrorMessage = () => {
    if (!/^\d+$/.test(formData.postCode)) {
      return 'Tu código es inválido';
    }

    if (formData.postCode.length === 5 && !validations.postCode) {
      return 'Tu código es inválido, continúa en Encontrar mi Código Postal';
    }

    return 'Escribe tu Código Postal a 5 dígitos';
  };

  // If user doesn't know the zip code or enters an invalid one, show the manual input screen
  // and add a tag invalid-address to identify them.
  const showManualInputBtnClick = () => {
    setShowManualInputs(true);
    // reset the form
    setFormData({
      street: '',
      streetNumberExt: '',
      streetNumberInt: '',
      locality: '',
      localityManualInput: '',
      postCode: '',
      city: '',
      addressState: '',
    });

    // reset validations
    setValidations({
      street: false,
      streetNumberExt: false,
      streetNumberInt: true,
      locality: false,
      localityManualInput: true,
      postCode: false,
      city: false,
      addressState: false,
    });

    if (!registrationFlowEvents.unknownZipCodeBtn) {
      unknownZipCodeRegisterEvent();
      updateRegistrationFlowEvents({ ...registrationFlowEvents, unknownZipCodeBtn: true });
    }
  };

  // Trigger event when the screen is displayed
  useEffect(() => {
    if (!registrationFlowEvents.addressDisplayed) {
      displayedFunnelScreensEvent('address');
      updateRegistrationFlowEvents({ ...registrationFlowEvents, addressDisplayed: true });
    }
  }, []);

  return (
    <Form.Step
      enableReinitialize
      onSubmit={handleFormStepSubmit}
    >
      {({
        isSubmitting,
        formErrorMsg,
        clearFormErrorMsg,
      }) => (
        <FormStepContainer>

          { showManualInputs
            ? (
              <UserAddressSearchForm
                showMainAddressScreen={() => setShowManualInputs(false)}
                getPostalCodeData={getPostalCodeData}
                setFormData={setFormData}
                setValidations={setValidations}
                setShowManualButton={setShowManualButton}
                setCityOptions={setCityOptions}
                setLocalityOptions={setLocalityOptions}
              />
            )
            : (
              <>
                {!formData.prospectUsedManualInputs ? (
                  <>
                    <Typography
                      variant="heading2"
                      marginBottom="8px"
                      whiteSpace="pre-line"
                    >
                      {'Ayúdanos a validar que \n vives en México'}
                    </Typography>

                    <Typography color="#000000A3">
                      Escribe tu código postal para confirmar tus
                      datos más rápido.
                    </Typography>

                  </>
                ) : (
                  <Typography
                    variant="heading3"
                    marginBottom="8px"
                  >
                    ¡Encontramos tu Código Postal! Completa el resto
                  </Typography>
                )}

                {/* ZIP */}

                <TextInput
                  label="Código Postal"
                  name="postCode"
                  id="postCode"
                  showButton={showManualButton}
                  buttonAction={() => showManualInputBtnClick()}
                  isLoading={isLoading}
                  isRequired
                  onChange={handlePostCodeChange}
                  value={formData.postCode}
                  maxLength={5}
                  error={formData.postCode && !validations.postCode}
                  errorMessage={getpostcodeErrorMessage(formData.postCode)}
                  isDisabled={formData.prospectUsedManualInputs}
                />

                {/*  STATE */}

                <Tooltip text={getTooltipMessage(formData?.addressState.length)}>
                  <CustomSelectField
                    name="addressState"
                    id="addressState"
                    externalValue={formData.addressState}
                    placeholder="Estado"
                    isDisabled
                    options={states}
                  />
                </Tooltip>

                {/* MUNICIPIO (CITY) */}

                <Tooltip text={getTooltipMessage(formData?.city.length)}>
                  <CustomSelectField
                    name="city"
                    id="city"
                    externalValue={formData.city}
                    placeholder="Alcaldía o Municipio"
                    isDisabled
                    options={cityOptions}
                  />
                </Tooltip>

                {/* COLONIA (LOCALITY) */}

                {formData?.city.length > 0 ? (
                  <CustomSelectField
                    name="locality"
                    id="locality"
                    externalValue={formData.locality}
                    placeholder="Colonia o Asentamiento"
                    onChange={handleSelectChange}
                    options={localityOptions}
                    isDisabled={formData.prospectUsedManualInputs}
                  />
                ) : (
                  <Tooltip text={formData.postCode.length === 5 && !validations.postCode
                    ? 'Continua en "Encontrar mi Código Postal"' : 'Comienza llenando el código postal'}
                  >
                    <CustomSelectField
                      name="locality-placeholder"
                      id="locality-placeholder"
                      value={formData.locality}
                      placeholder="Colonia o Asentamiento"
                      isDisabled={stateAndCityAreEmpty(formData?.addressState, formData?.city)}
                      options={[]}
                    />
                  </Tooltip>
                )}

                {/* LOCALITY NOT FOUND (MANUAL INPUT) */}

                {formData?.locality === LOCALITY_NOT_FOUND && (
                <TextInput
                  label="Colonia"
                  name="localityManualInput"
                  id="localityManualInput"
                  isRequired
                  onChange={handleLocalityManualInput}
                  value={formData.localityManualInput}
                  error={formData.localityManualInput && !validations.localityManualInput}
                  errorMessage={formData.localityManualInput.length < 3 ? 'Ingresa una colonia válida' : 'No se permiten caracteres especiales ($#*&@)'}
                />
                )}

                {/* STREET */}

                <TextInput
                  label="Calle"
                  name="street"
                  id="street"
                  isRequired
                  isDisabled={!validations.postCode}
                  onChange={handleChange}
                  value={formData.street}
                  error={formData.street && !validations.street}
                  errorMessage={formData.street.length < 3 ? 'Ingresa una calle válida' : 'No se permiten caracteres especiales ($#*&@)'}
                />

                {/* EXT & INT NUMBER */}

                <NumberInputsContainer>
                  <TextInput
                    label="N.º Ext."
                    name="streetNumberExt"
                    id="streetNumberExt"
                    isRequired
                    isDisabled={!validations.postCode}
                    onChange={handleChange}
                    value={formData.streetNumberExt}
                    error={formData.streetNumberExt && !validations.streetNumberExt}
                    errorMessage="Solo se permiten números"
                  />

                  <TextInput
                    label="N.º Int. (opcional)"
                    name="streetNumberInt"
                    id="streetNumberInt"
                    isDisabled={!validations.postCode}
                    onChange={handleChange}
                    value={formData.streetNumberInt}
                    error={formData.streetNumberInt && !validations.streetNumberInt}
                  />
                </NumberInputsContainer>

                {/* BOTTOM ROW */}

                <Box marginTop="auto">

                  <StyledSection
                    display="flex"
                    alignItems="center"
                    marginTop="40px"
                    marginBottom="4x"
                  >
                    <Disclaimer />
                    <Button
                      id="address-step-submit-btn"
                      type="submit"
                      variant="contained"
                      styleVariant="primary"
                      disabled={!isFormValid || isSubmitting}
                      iconProps={{ name: 'arrow-right', title: 'Buscar Código Postal' }}
                    />

                  </StyledSection>

                </Box>

              </>
            )}

          {/* DISCLAIMER TEXT */}

          <Snackbar
            visible={Boolean(formErrorMsg)}
            onActionClick={clearFormErrorMsg}
            actionText="Cerrar"
          >
            {formErrorMsg}
          </Snackbar>

        </FormStepContainer>
      )}
    </Form.Step>
  );
};

ZipAddressStepWithSearch.defaultProps = {
  shouldTriggerOTP: false,
};

ZipAddressStepWithSearch.propTypes = {
  shouldTriggerOTP: PropTypes.bool,
};

export default ZipAddressStepWithSearch;
