import React, { useEffect, useRef, useState } from 'react';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Box from '@nubank/nuds-web/components/Box/Box';
import Form from '@nubank/nuds-web/components/Form/Form';
import Button from '@nubank/nuds-web/components/Button/Button';
import Snackbar from '@nubank/nuds-web/components/Snackbar/Snackbar';

import Image from '@nubank/www-latam-commons/components/Image/Image';
import { cookieStorage } from '@nubank/www-latam-commons/utils/storage';
import { registerProspect } from '@nubank/www-latam-commons/services/prospect';

import {
  confirmAuthorizationRequest, assignProspectToAuthRequest, resendAuthorizationRequest,
} from '../../../../domains/prospect/bureauAuthorizationRequest';
import { useSiteContext } from '../../../../components/SiteContext/SiteContext';
import { ERROR_SEVERITY, sentryException } from '../../../../utils/sentry';
import { MULTI_PRODUCT, getProspectType } from '../../../../utils/prospectTypes';
import { mapFormPospectToWire } from '../../../../utils/form/mapFormPospectToWire';
import { mapFormAdditionalParametersToWire } from '../../../../utils/form/mapFormAdditionalParametersToWire';
import { useRegistrationFormContext } from '../RegistrationForm/RegistrationForm';
import {
  nipStepRegisterEvent,
  userIdGA4,
  displayedFunnelScreensEvent,
  resendOTPRegisterEvent,
  otpExperimentEvent,
} from '../../tracking';

import {
  StyledButton, StyledPhoneTypography,
  StyledSection, StyledSubtitle, StyledTextButton,
  StyledTextField, StyledTitle,
} from './Styled';
import PhoneEditDrawer from './PhoneEditDrawer';

const TIMER_DURATION_SECONDS = 30;
const GA_CLIENT_ID_COOKIE = '_ga';

function BuroValidationOTP() {
  const nipInputRef = useRef();
  const {
    authorizationRequest, phone, discoveryUrlsList,
    registrationFlowEvents, updateRegistrationFlowEvents,
  } = useSiteContext();
  const { setProspectRegistrationData, selectedAddress } = useRegistrationFormContext();

  const [resendTimer, setResendTimer] = useState(null);
  const [resendTimerCount, setResendTimerCount] = useState(0);
  const [isResendTimerRunning, setIsResendTimerRunning] = useState(false);
  const [resendSuccessStatus, setResendSuccessStatus] = useState(false);
  const [showPhoneEditModal, setShowPhoneEditModal] = useState(false);
  const [resendCount, setResendCount] = useState(1);

  const stopResendTimer = () => {
    setIsResendTimerRunning(false);
    setResendTimer(null);
    clearInterval(resendTimer);
    setResendTimerCount(0);
  };

  const startResendTimer = () => {
    setIsResendTimerRunning(true);
    setResendTimerCount(TIMER_DURATION_SECONDS);
    setResendTimer(setInterval(() => {
      setResendTimerCount(currentCount => currentCount - 1);
    }, 1000));
  };

  const handleResendNIP = async setFormErrorMsg => {
    resendOTPRegisterEvent();
    otpExperimentEvent(`resend ${resendCount}`);
    setResendCount(resendCount + 1);
    const channels = ['sms', 'whatsapp'];

    try {
      await resendAuthorizationRequest(authorizationRequest.id, discoveryUrlsList, channels);
      startResendTimer();
      setResendSuccessStatus(true);
    } catch (error) {
      sentryException(error, ERROR_SEVERITY.ERROR);
      setFormErrorMsg('No se pudo reenviar el código, por favor intenta de nuevo');
    }
  };

  useEffect(() => {
    nipInputRef.current?.focus();
    if (!registrationFlowEvents.nipDisplayed) {
      displayedFunnelScreensEvent('nip');
      updateRegistrationFlowEvents({ ...registrationFlowEvents, nipDisplayed: true });
    }
  }, []);

  useEffect(() => {
    if (resendTimer && resendTimerCount <= 0) {
      stopResendTimer();
    }
  }, [resendTimerCount]);

  const handleSubmit = async ({
    nextStep,
    values,
    setSubmitting,
    formsValues,
    setFormErrorMsg,
  }) => {
    try {
    // Retrieve and set GA Client ID
      const gaClientId = cookieStorage.getString(GA_CLIENT_ID_COOKIE);

      // Confirm authorization response
      const confirmationAuthResponse = await confirmAuthorizationRequest({
        id: authorizationRequest.id,
        nip: values.nip,
        phone,
        rfc: formsValues.taxId,
        urls: discoveryUrlsList,
      });

      // Build prospect payload
      const prospectType = getProspectType(MULTI_PRODUCT);

      const registrationPayload = mapFormPospectToWire({ ...formsValues, ...selectedAddress });

      const additionalParameters = mapFormAdditionalParametersToWire(
        { ...formsValues },
        gaClientId,
        confirmationAuthResponse.id,
      );

      // Register prospect
      const prospectRegistrationResponse = await registerProspect(
        prospectType, registrationPayload, additionalParameters,
      );

      // Assign created prospect to authorization request
      if (prospectRegistrationResponse?.prospectId) {
        userIdGA4(prospectRegistrationResponse.prospectId);

        await assignProspectToAuthRequest({
          prospectId: prospectRegistrationResponse.prospectId,
          authorizationRequestId: confirmationAuthResponse.id,
          discoveryUrlsList,
        });
      }

      // save propect registration data for later use on next screens
      setProspectRegistrationData({
        ...prospectRegistrationResponse,
        prospectType: prospectType.type,
      });

      setSubmitting(false);

      // We are using GOAL 7 NIP so it matches experiment vs control
      // only register if it was successful
      if (!registrationFlowEvents.otpSent) {
        nipStepRegisterEvent();
        updateRegistrationFlowEvents({ ...registrationFlowEvents, otpSent: true });
      }

      nextStep();
    } catch (error) {
      sentryException({
        error,
        flow: 'application_flow',
        checkpoint: 'confirmAuthorizationRequest && registerProspect',
        namespace: '<BuroValidationOTP />',
        level: ERROR_SEVERITY.CRITICAL,
      });

      setSubmitting(false);
      otpExperimentEvent('invalid');
      setFormErrorMsg('No se pudo confirmar tu código, por favor intenta de nuevo');
    }
  };

  const handleChangePhone = () => {
    setShowPhoneEditModal(true);
    otpExperimentEvent('phone change');
  };

  return (
    <Form.Step onSubmit={handleSubmit}>
      {({
        isDisabled, isSubmitting, clearFormErrorMsg, formErrorMsg, setFormErrorMsg,
      }) => (

        <Box
          padding={{ xs: '1.5rem', lg: '2.5rem' }}
          overflow="auto"
          height="100%"
          display="flex"
          flexDirection="column"
        >
          <StyledTitle
            variant="heading2"
            marginBottom="4x"
            whiteSpace="pre-line"
          >
            {'¡Ya casi! Acepta la \n consulta a buró'}
          </StyledTitle>

          <StyledSubtitle
            color="#000000A3"
            variant="paragraph1"
            marginBottom="8x"
            whiteSpace="pre-line"
          >
            Con el
            <span>{' código que enviamos a tu \n celular'}</span>
            {' aceptas la consulta de tu \n historial crediticio a favor de Nu México \n Financiera, S.A. de C.V., S.F.P.'}
          </StyledSubtitle>

          <StyledPhoneTypography
            variant="heading4"
            color="primary.default"
          >
            {phone}
          </StyledPhoneTypography>

          <StyledButton type="button" onClick={() => handleChangePhone(true)}>
            <StyledTextButton
              color="primary"
              strong
              marginTop="2x"
            >
              Corregir mi número
              {' '}
              <Image
                alt="Icono de una bolsa"
                src="one-step/custom-pencil.svg"
                width="16px"
                height="16px"
                webp={false}
              />
            </StyledTextButton>
          </StyledButton>

          <StyledTextField
            marginTop="4x"
            id="nip"
            name="nip"
            maxLength="4"
            autoComplete="off"
            label="Escribe o pega el código de 4 dígitos"
            syncValidations={{
              nip: 'Por favor verifica que tu código sea correcto',
              required: 'Por favor verifica que tu código sea correcto',
            }}
            ref={nipInputRef}
          />

          {/* CUSTOM RESEND BUTTON */}

          <StyledButton
            type="button"
            onClick={() => handleResendNIP(setFormErrorMsg)}
            disabled={isResendTimerRunning}
          >

            <StyledTextButton
              color="primary"
              strong
            >
              Enviar código nuevamente
              {' '}
              <Image
                alt="Icono de una bolsa"
                src="one-step/custom-arrow.svg"
                width="16px"
                height="16px"
                webp={false}
              />
            </StyledTextButton>
          </StyledButton>

          {/* RESEND STATUS */}

          {isResendTimerRunning
            && (
            <Typography
              marginTop="4x"
              variant="paragraph2"
            >
              Podrás enviar un nuevo código en
              {' '}
              {resendTimerCount}
              {' '}
              segundos...
            </Typography>
            )}

          <Box paddingTop="4x">
            {(resendSuccessStatus && isResendTimerRunning)
             && (
             <Typography
               color="success"
               variant="paragraph2"
               strong
               data-testid="resend-nip"
               marginBottom
             >
               ¡Listo! Ya enviamos nuevamente el número a tu celular
             </Typography>
             )}
          </Box>

          {/* BOTTOM DISCLAIMER AND BTN */}

          <StyledSection
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            marginTop="auto"
          >

            <Button
              id="buro-validation-step-submit-btn"
              variant="contained"
              styleVariant="primary"
              type="submit"
              iconProps={{ name: 'arrow-right', title: 'Buscar Código Postal' }}
              disabled={isDisabled || isSubmitting}
            >
              Continuar
            </Button>

            <Snackbar
              visible={Boolean(formErrorMsg)}
              onActionClick={clearFormErrorMsg}
              actionText="Cerrar"
            >
              {formErrorMsg}
            </Snackbar>
          </StyledSection>

          <PhoneEditDrawer
            setShowPhoneEditModal={setShowPhoneEditModal}
            showPhoneEditModal={showPhoneEditModal}
          />

        </Box>
      )}
    </Form.Step>
  );
}

export default BuroValidationOTP;

